import React, { useEffect, useState,useRef } from 'react';


const MyCustomEditor = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [content, setContent] = useState('');
 // const iframeRef = useRef(null);
 const editorRef = useRef(null);


  const handleAddComponent = (component) => {
    
    setContent(editorRef.current.innerHTML + component);
  };



  const components = [
    { label: 'Heading 1', tag: '<h1>Heading 1</h1>' },
    { label: 'Paragraph', tag: '<p style="text-align: center">This is a paragraph.</p>' },
    { label: 'Image', tag: '<img src="https://via.placeholder.com/150" alt="Placeholder" />' },
    {label:'info divider',tag:'<div style="padding: 20px; color: white; background-color: #343a40; text-align: center;"> <h2>Custom Block</h2> <p>This is a custom block.</p> </div>'}
  ];

  return (
    <div style={{ position: 'relative', height: '100vh' }}>
      {/* Editor container */}
      <div id="my-editor" ref={editorRef} contenteditable="true" dangerouslySetInnerHTML={{ __html: content }}  style={{ width: '100%', height: '100%' }}></div>

      {/* Toggle button to open/close the sidebar */}
      <button
        onClick={() => setSidebarOpen(!sidebarOpen)}
        style={{
          position: 'fixed',
          top: '50%',
          right: sidebarOpen ? '200px' : '0px',  // Adjusts button position based on sidebar state
          transform: 'translateY(-50%)',
          zIndex: 999,
          backgroundColor: '#007bff',
          color: '#fff',
          border: 'none',
          padding: '10px',
          cursor: 'pointer',
        }}
      >
        {sidebarOpen ? '⇛' : '⇚'}
      </button>

      {/* Sidebar container for components panel */}
      <div
        style={{
          position: 'fixed',
          top: '0',
          right: sidebarOpen ? '0' : '-200px',  // Slide in/out based on state
          width: '200px',
          height: '100%',
          backgroundColor: '#f4f4f4',
          boxShadow: '-2px 0px 5px rgba(0,0,0,0.5)',
          zIndex: 998,
          transition: 'right 0.3s ease',  // Smooth transition effect
          overflowY: 'auto',              // Scroll if content overflows
        }}
      >
        <div id="my-blocks" style={{ padding: '10px' }}>
          <h3>Components</h3>
          <ul>
          {components.map((comp, index) => (
            <li key={index} onClick={() => handleAddComponent(comp.tag)} style={{ cursor: 'pointer' }}>
              {comp.label}
            </li>
          ))}
        </ul>
        </div>
      </div>
    </div>
  );
};

export default MyCustomEditor;
