// src/TemplateGrid.js
import React from 'react';
import './css/TemplateGrid.css'; // Import CSS for styles

const templates = [
  { id: 1, src: 'http://apinorth.com/seat/template-gallery//index.jpg', title: 'Template 1' },
  { id: 2, src: './index.jpg', title: 'Template 2' },
  { id: 3, src: './index.jpg', title: 'Template 3' },
  // Add more templates as needed
];

const Templates = () => {
  return (
    <div className="grid-container">
      {templates.map(template => (
        <div className="grid-item" key={template.id}>
          <img src={template.src} alt={template.title} className="template-image" />
          <div className="overlay">
            <button className="btn edit">Edit</button>
            <button className="btn preview">Preview</button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Templates;
