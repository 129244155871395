import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import Templates from "./Templates";
import reportWebVitals from './reportWebVitals';
import CustomEditor from './CustomEditor';
import MyCustomEditor from './MobileEditor';

import App from "./App";
import { BrowserRouter as Router, Route, Routes, Link,useParams ,useNavigate  } from 'react-router-dom';
// Define your components


const Home = () => {
  const [savedTemplates, setSavedTemplates] = useState([]);

  useEffect(() => {
    // Fetch templates from API
    const fetchTemplates = async () => {
      try {
        const response = await fetch("https://api.apinorth.com/email-templates");
        const data = await response.json();

        const formattedTemplates = data.map((template) => ({
          id: template._id,
          name: `Template ${template._id}`, 
          content: `<style>${template.css}</style>${template.html}`,
        }));

        setSavedTemplates(formattedTemplates);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };


    fetchTemplates();
  }, []);
  const navigate = useNavigate();

  const handleButtonClick = (id) => {
    navigate(`/Editor/${id}`);
  };
  
  return ( 
    <ul style={{ listStyle: "none", padding: 0 }}>
    {savedTemplates.map((tmpl) => (
      <li key={tmpl.id} style={{ marginBottom: "10px" }}>
        <button
          onClick={() => handleButtonClick(tmpl.id)}
          style={{ width: "100%", padding: "10px", textAlign: "left", backgroundColor: "#fff", border: "1px solid #ddd", borderRadius: "4px" }}
        >
          {tmpl.name}
        </button>
      </li>
    ))}
  </ul>
  );
};



const About = () => <h2>About Page</h2>;
const Contact = () => {
  let { templateId } = useParams();
  
  return <h2>User ID: {templateId}</h2>;
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
 

    <Routes>
    <Route path="/" element={<CustomEditor />} />

      <Route path="/:templateId" element={<CustomEditor />} />
      <Route path="/Editor/:templateId" element={<CustomEditor />} />
      <Route path="/Editor" element={<CustomEditor />} />
      <Route path="/templates" element={<Templates />} />
      <Route path="/mobile" element={<MyCustomEditor />} />

    </Routes>
  </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
