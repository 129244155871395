// src/components/EmailBuilder.js
import React, { useEffect,useState } from "react";
import grapesjs, { Button } from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import pluginNewsletter from "grapesjs-preset-newsletter";
import { useParams ,useNavigate } from 'react-router-dom';
import './css/editor.css';
import grapesjsTouch from 'grapesjs-touch';

const EmailBuilder = () => {

  const [savedTemplates, setSavedTemplates] = useState([]);
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);

 // const [editor,setEditor] = useState();
  let { templateId } = useParams();
  if(templateId==null||templateId==''){
    templateId='1750';
  }
  var fetchTemplates = async () => {
    try {
      const response = await fetch("https://api.apinorth.com/email-templates");
      const data = await response.json();

      const formattedTemplates = data.map((template) => ({
        id: template._id,
        name: `Template ${template._id}`, 
        html: template.html,
        content: `<style>${template.css}</style>${template.html}`,
      }));

      setSavedTemplates(formattedTemplates);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };
  useEffect(() => {
    var fetchTemplates = async () => {
      try {
        const response = await fetch("https://api.apinorth.com/email-templates");
        const data = await response.json();

        const formattedTemplates = data.map((template) => ({
          id: template._id,
          name: `Template ${template._id}`, 
          html: template.html,
          content: `<style>${template.css}</style>${template.html}`,
        }));

        setSavedTemplates(formattedTemplates);
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    
    fetchTemplates();
    
  }, []);

  useEffect(() => {
    const editor = grapesjs.init({
      container: "#gjs",
      height: "100vh",
      plugins: [pluginNewsletter,grapesjsTouch],
      pluginsOpts: {
        [pluginNewsletter]: {},
      },
      storageManager: false, // Disable storage for now
      assetManager: {
        //upload: 'https://apinorth.com/seat/upload.php', // API endpoint for image upload
        uploadName: 'image', // Form field name where file is placed in the POST request
        multiUpload: false, // Allow multiple file uploads (change to true if needed)
        autoAdd: true, // Automatically add uploaded images to the asset manager
        assets: [], // Initially empty, will be filled with JSON images later
        uploadFile: (e) => {
          const file = e.target.files[0]; // Get the single file being uploaded
          const formData = new FormData();
          formData.append('image', file); // Append the file with the correct field name
  
          fetch('https://apinorth.com/seat/upload.php', {
            method: 'POST',
            body: formData,
          })
          .then((res) => res.json())
          .then((result) => {
            if (result.success) {
              // Add the uploaded image to the asset manager
              editor.AssetManager.add({
                src: result.url, // The URL of the uploaded image from the response
                name: result.url.split('/').pop(), // Optional: Set the image name as the file name
              });
            }
          })
          .catch((err) => console.error('Upload error:', err));
        },

      },
      panels: {
        defaults: [
          {
            id: "panel-top",
            el: ".panel__top",

          },
          {
            id: "basic-actions",
            el: ".panel__basic-actions",
            buttons: [
       
      
            ],
          },
        ],
         // Add responsive settings
      media: {
        // Mobile first breakpoint
        'width':'600px',
        'margin-right':'20px'

      },
      },

    });
    const fetchTemplateData = async () => {
      try {
          const response = await fetch('https://apinorth.com/seat/get-template.php?id='+templateId); // Replace with your API URL
          const data = await response.json();
          editor.setComponents("<style>"+data.css+"</style>"+data.html)
      } catch (error) {
          console.error('Error fetching template data:', error);
      }
  };

  fetchTemplateData();
 // Ensure panel visibility by appending it to the editor's UI
 editor.Panels.addPanel({
  id: "basic-actions",
  el: ".panel__basic-actions",
  buttons: [
    {
      id: "save",
      className: "btn-save",
      label: "Save",
      command: "save-db",
      context: "save",
    },
  ],
});
    // Custom command to save HTML and CSS
    editor.Commands.add("save-db", {
      run(editor, sender) {
        sender && sender.set("active", 0); // Turn off the button
        const htmlContent = editor.getHtml();
      const cssContent = editor.getCss();

      fetch("https://api.apinorth.com/email-templates", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          html: htmlContent,
          css: cssContent,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          alert("Template saved successfully!");
          fetchTemplates();
        })
        .catch((error) => {
          console.error("Error saving template:", error);
        });
      },
    });

    // Load images from JSON into asset manager
    const loadImagesFromJson = async () => {
      try {
        // Simulating a fetch call to get images (replace with your own API)
        const response = await fetch('https://apinorth.com/seat/uploads/'); // This is the JSON endpoint
        const imageList = await response.json();
        
        // Adding assets to GrapesJS
        editor.AssetManager.add(imageList.map(img => ({
          type: 'image',
          src: img.url,  // Image URL from JSON
          name: img.name // Optional: image name
        })));
      } catch (error) {
        console.error("Failed to load images", error);
      }
    };

    // Load images from JSON when the editor is ready
    loadImagesFromJson();
    //setEditor(editor);

  }, []);
  const navigate = useNavigate();

  const handleButtonClick = (id) => {
    //navigate(`/Editor/${id}`);
  };
  const toggleSidebar=()=>{
    setIsSidebarHidden(!isSidebarHidden);

  };
  // Function to toggle visibility of elements with a specific class
const toggleElementVisibilityByClass = (className) => {
  const elements = document.getElementsByClassName(className);
  if (elements.length > 0) {
      for (let element of elements) {
          if (element.style.display === 'none' || getComputedStyle(element).display === 'none') {
              element.style.display = ''; // Show the element
          } else {
              element.style.display = 'none'; // Hide the element
          }
      }
  } else {
      console.log(`No elements found with class: ${className}`);
  }
};
  const toggleComponentsPanel=()=>{
    toggleElementVisibilityByClass('gjs-pn-views-container');
    toggleElementVisibilityByClass('gjs-pn-views');


  };


  return (
    <>
             <nav class="navbar navbar-expand-lg navbar-light bg-light mobile-only">
    <div class="container-fluid">
        <a class="btn btn-primary" style={{backgroundColor:"blue"}} onClick={toggleSidebar} role="button">》</a>

        <div class="ms-auto">
            <a class="btn btn-secondary" style={{backgroundColor:"blue"}} onClick={toggleComponentsPanel} role="button">《</a>
        </div>
    </div>
</nav>
    <div style={{ display: 'flex', height: '100vh' }}>


      {/* Sidebar */}
      <div className={`sidebar ${isSidebarHidden ? 'hidden' : ''}`}>
      <button onClick={handleButtonClick}>Send Email</button>

        <h3>Saved Templates</h3>

        {savedTemplates.map((tmpl) => (
        
          
        <div key={tmpl.id} style={{ position: 'relative', margin: 10 }}>
        <iframe
          srcDoc={tmpl.content}
          className="template-thumbnail"
          scrolling="no"
        />
         <a
            href={`/Editor/${tmpl.id}`}
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              zIndex: 1,
              backgroundColor: 'blue',
              color: 'white',
              textDecoration: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              display: 'inline-block',
              textAlign: 'center',
            }}
          >
            Edit
          </a>
      </div>
          
          ))}
      </div>

      {/* Main content */}
      
      <div style={{ flex: 1 }}>
        
        <div className="panel__top" style={{backgroundColor: '#f4f4f4', borderBottom: '1px solid #ddd' }}>
          <div className="panel__basic-actions"></div>
        </div>
        <div id="gjs"></div>
      </div>
    </div>
    </>
  );
};

export default EmailBuilder;
